import React from 'react'
import './Footer.css'
import Github from '../../assets/github.png'
import Instagram from '../../assets/instagram.png'
import Linkedin from '../../assets/linkedin.png' 
import Logo from '../../assets/logo.png'
function Footer() {
  return (
    <div className="Footer">
       <div className="blur f-blur1"></div>
      <div className="blur f-blur2"></div>
     
        <div className="footer-img">
            <img src={Linkedin} alt="" />
            <img src={Instagram} alt="" />
            <img src={Github} alt="" />
        </div>

        <div className='footer-logo'>
        <img src={Logo} alt="" style={{height:"4rem", width:'10rem', cursor:"pointer"}}/>
        </div>
    
    </div>
  )
}

export default Footer