import './App.css';
import Footer from './Component/Footer/Footer';
import Hero from './Component/Hero/Hero';
import Join from './Component/Join/Join';
import Plans from './Component/Plans/Plans';
import Programs from './Component/Programs/Programs';
import Reason from './Component/Reason/Reason';
import Testinomial from './Component/Testinomial/Testinomial';
function App() {
  return (
    <div className="App">
     <Hero/>
     <Programs/>
     <Reason/>
     <Plans/>
     <Testinomial/>
     <Join/>
     <Footer/>
    </div>
  );
}

export default App;
