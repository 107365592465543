import React, { useState } from 'react'
import Header from '../Header/Header'
import Hero_image from '../../assets/hero_image.png'
import Hero_image_back from '../../assets/hero_image_back.png'
import Heart from '../../assets/heart.png'
import Calorie from '../../assets/calories.png'
import './Hero.css'
import {motion, useSpring} from 'framer-motion'
import NumberCounter from 'number-counter'

function Hero() {
    const transition ={ type:'useSpring', duration:3}
    const mobile=window.innerWidth<=768? true : false;
   
  return (
    <div className="Hero">
        <div className='blur h-blur'></div>
    <div className="h-left">
        
       <Header/>

{/* best-ad  */}
       <div className="the-best-ad">
        
        <motion.div
        initial={{left: mobile? '220px':'230px'}}
        whileInView={{left:'9px'}}
        transition={{...transition, type:'tween'}}
        ></motion.div>

        <span>the best ad is here in your town</span>
       </div>

{/* hero heading */}
        <div className="hero-text">
            <div>
            <span className='stroke-text'>Shape </span>
            <span>Your</span>
            </div>
            <div>
            <span>Ideal Body</span>  
            </div>
            <div>
                <span>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio ex velit animi fuga consequuntur aut!
                </span>
            </div>
    {/* Figures */}
            <div className="h-experience">
                <div>
                    <span>
                        <NumberCounter end={140} start={100} delay='4' preFix="+"/>
                    </span>
                    <span>Expert Coaches</span>
                </div>
                <div>
                    <span> <NumberCounter end={400} start={340} delay='4' preFix="+"/></span>
                    <span>Memeber Joined</span>
                </div>
                <div>
                    <span> <NumberCounter end={50} start={10} delay='2' preFix="+"/></span>
                    <span>fitness program</span>
                </div>
            </div>
            <div className="h-button">
                <button className='button h-btn'>Get Started</button>
                <button className='button h-btn'>Learn More</button>
            </div>
        </div>

    </div>
{/* right side */}
    <div className="h-right">
    <button className='button'>Join Now</button>

     <motion.div
     initial={{right: '40px'}}
     whileInView={{right:'120px'}}
     transition={{...transition}}
     className="heart-rate">
        <img src={Heart} alt="" />
        <span>Heart Rate</span>
        <span>160bpm</span>
     </motion.div>
       <img src={Hero_image} alt="" className='hero-image' />
       <motion.img
       initial={{right:'40px'}}
       whileInView={{right:'360px'}}
       transition={{...transition, type:"tween"}}
       src={Hero_image_back} alt="" className='hero-image-back'/>
       
       <motion.div
       initial={{right:'32rem'}}
       whileInView={{right:'25rem'}}
       transition={{...transition}}
       className="calories">
        <img src={Calorie} alt="" />
        <div>
        <span>Calories Burned</span>
        <span>220 calories</span>
        </div>
     </motion.div>

    </div>

    </div>
  )
}

export default Hero