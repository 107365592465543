import React from 'react'
import './Reason.css'
import Tick from '../../assets/tick.png'
import Image1 from '../../assets/image1.png'
import Image2 from '../../assets/image2.png'
import Image3 from '../../assets/image3.png'
import Image4 from '../../assets/image4.png'
import Adidas from '../../assets/adidas.png'
import Nike from '../../assets/nike.png'
import Nb from '../../assets/nb.png' 
function Reason() {
  return (
    <div className="Reason">
      <div className="r-left">
      <img src={Image1} alt="" />
      <img src={Image2} alt="" />
      <img src={Image3} alt="" />
      <img src={Image4} alt="" />
      </div>
      <div className="r-right">
        <span>Some Reason</span>
        <div>
          <span className='stroke-text'>Why </span>
          <span>Choose Us</span>
        </div>

        <div className='r-detail'>
        <div>
        <img src={Tick} alt=""></img>
        <span>OVER 140+ EXPERT COACHES</span>
        </div>
        <div>
          <img src={Tick} alt="" />
          <span>TRAIN SMARTER AND FASTER THAN BEFORE</span>
          </div>
        <div>
          <img src={Tick} alt="" />
          <span>1 FREE PROGRAM FOR NEW MEMBER</span>
          </div>
        <div>
          <img src={Tick} alt="" />
          <span>RETIALABLE PARTNERS</span>
          </div>
        </div>
        <span
        style={{
        fontWheight:'normal',
        color:'var(--gray)'
        }}>
         Our Partners
        </span>
        <div className="partners">
           <img src={Adidas} alt="" />
           <img src={Nike} alt="" />
           <img src={Nb} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Reason