import React from 'react'
import './Programs.css'
import Programdata, { programsData } from '../../data/programsData.js'
import Arrow from '../../assets/rightArrow.png'
function Programs() {
  return (
    <div className="Programs" id="programs">
     
      {/* Header of programs */}
    <div className="P-header">
      <span className='stroke-text'>Expolore us </span>
      <span> Programs </span>
      <span className='stroke-text'> to shape your</span>
      </div> 
    

    <div className="Program-catagories">
      <div className="blur p-blur1"></div>
      <div className="blur p-blur2"></div>
      {programsData.map((programs)=>
      <div className="programs">
        {programs.image}
        
        <span>
          {programs.heading}
        </span>
        <span>
             {programs.details}
        </span>
        <div className="joinNow">
            <span>Join Now</span>
            <img src={Arrow} alt="" />
        </div>
      </div>
      )}
    </div>
    </div>
  )
}

export default Programs