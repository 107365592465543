import React from 'react'
import { plansData } from '../../data/plansData'
import './Plans.css'
import Whitetick from '../../assets/whiteTick.png'
import Rightarrow from '../../assets/rightArrow.png'
function Plans() {
  return (
    <div className="Plans-container" id='Plan'>
        <div className="p-header">
            <span className='stroke-text'>Ready to Start</span>
            <span>Your Journey</span>
            <span className='stroke-text'>Now WithUs</span>
        </div>
        <div className="blur p-blur1"></div>
      <div className="blur p-blur2"></div>
        {/* Plans */}
        <div className="plans">
       
        {plansData.map((plan, i)=>(
            <div className="plan" key={i}>
                <span>{plan.icon}</span>
                <span>{plan.name}</span>
                <span>{plan.price}</span>
                <div className="features">
                {plan.features.map((feature, i)=>(
                    <div className="feature">
                        <img src={Whitetick} alt="" style={{color:'white'}}/>
                        <span>{feature}</span>
                    </div>
                ))}
                <div className='benefits'>
                <span>See More Benefits</span>
                <img src={Rightarrow} alt="" />
                </div>
                </div>
                
                <button className='button'>Join Now</button>         
          </div>
        ))}
        </div>
    </div>
  )
}

export default Plans