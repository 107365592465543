import React from 'react'
import './Join.css'
import emailjs from '@emailjs/browser'
import { useRef } from 'react'

function Join() {
    const form =useRef()

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_phj7x7n', 'template_oz4dhjc', form.current, 'mP0wT_M9BHXKkJhey')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
        }
  return (
    <div className="Join">
       <div className="l-join">
        <div>
            <hr/>
        <span className='stroke-text'>Ready to </span>
        <span>Level Up</span>
        </div>
        <div>
        <span>Your Body </span>
        <span className='stroke-text'>With Us</span>
        </div>
    </div>
    {/* Right Side */}
       <div className="r-join">

       <form ref={form} className='email-container' onSubmit={sendEmail}>
        <input type="email" name='user_email' placeholder='Enter your Email Address'/>
        <button className='btn btn-j' >Send</button>
       </form>

       </div>
    </div>
  )
}

export default Join