import React, { useState } from 'react'
import './Header.css'
import Logo from '../../assets/logo.png'
import Bar from '../../assets/bars.png'
import {Link, link} from 'react-scroll'

function Header() {
  const mobile= window.innerWidth <=768 ? true : false;
 const [menuOpened, setMenuOpened]=useState(false);

  return (
    <div className="Header">
        <img src={Logo} alt="" />
{(menuOpened==false && mobile==true) ? (
<div>
  <img src={Bar} alt="" 
  style={{width:'2rem', height:'2rem',
   marginTop:'4px', backgroundColor:'var(--appColor)' , padding:'10px'
  , borderRadius:'4px'
  }}
   onClick={()=>setMenuOpened(true)}
   />

</div>
): (<ul className='header-menu'>
            <li ><Link 
            onClick={()=>setMenuOpened(false)}
            to='Hero'
            span={true}
            smooth={true}
            >Home</Link></li>

            <li ><Link
            onClick={()=>setMenuOpened(false)}
            to='Programs'
            span={true}
            smooth={true}
            >Programs</Link> </li>


            <li ><Link
             onClick={()=>setMenuOpened(false)}
             to='Reason'
             span={true}
             smooth={true}
            >Why us
            </Link></li>


            <li ><Link
             onClick={()=>setMenuOpened(false)}
             to='Plans-container'
             span={true}
             smooth={true}
            >Plans</Link></li>


            <li ><Link
            onClick={()=>setMenuOpened(false)}
            to='testinomial'
            span={true}
            smooth={true}
            >Testinomials</Link></li>

            
        </ul>)
        }
       </div>
    
  )
}

export default Header