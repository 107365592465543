import React from 'react'
import './Testinomial.css'
import Leftarrow from '../../assets/leftArrow.png'
import Rightarrow from '../../assets/rightArrow.png'
import { testimonialsData } from '../../data/testimonialsData'
import { useState } from 'react'
import {motion, useSpring} from 'framer-motion'
function Testinomial() {
    const transition={type:'useSpring', duration:1};

    const [selected, setSelected]=useState(0);
    const tLength=testimonialsData.length;

  return (
    
    <div className="testinomial" id='Testimonial'>
        {/* left side */}
        <div className="t-left">
            <span>Testinomial </span>
            <span className='stroke-text'>What They </span>
            <span>Say About Us </span>
            <motion.span
            key={selected}
            initial={{opacity:0, x:-100}}
            animate={{opacity:1, x:0}}
            exit={{opacity:0, x:100}}
            transition={{...transition}}
            >
                {testimonialsData[selected].review}
            </motion.span>
            <span>
                <motion.span
                 key={selected}
                 initial={{opacity:0, x:-100}}
                 animate={{opacity:1, x:0}}
                 exit={{opacity:0, x:100}}
                 transition={{...transition}}>
                {testimonialsData[selected].name}
                </motion.span>
            {" "}
                -{testimonialsData[selected].status}
            </span>

        </div>

        {/* Right Side */}
        <div className="t-right">
            <motion.div
            initial={{right:'25rem'}}
            whileInView={{right:'12rem'}}
            transition={{...transition}}
            >
            </motion.div>
            <motion.div
             initial={{right:'5rem'}}
             whileInView={{right:'10rem'}}
             transition={{...transition}}
            >

            </motion.div>
        <motion.img 
        key={selected}
         initial={{opacity:0, x:100}}
         animate={{opacity:1, x:0}}
         exit={{opacity:0, x:-100}}
         transition={{...transition}}
        src={testimonialsData[selected].image} alt="" />
            <div
             className="arrows">
                <img
                onClick={()=>{
               selected==0?
               setSelected(tLength-1):
               setSelected((prev)=> prev - 1 );
                }
                }
                src={Leftarrow} alt="" />
                <img
                onClick={()=>{
                    selected= tLength - 1
                    ?setSelected(0)
                    :setSelected((prev) => prev + 1);
                }}
                src={Rightarrow} alt="" />
            </div>
        </div>
    </div>
  )
}

export default Testinomial